<template>
  <v-container fluid align-center class="pt-15">
    <v-row justify="center" style="background-color: #5271ff;">
      <v-col cols="12" md="4">
        <v-sheet v-if="catalogues.categorias.length" elevation="5" class="mt-2 v-step-1" rounded>
          <v-chip-group v-model="filter" class="mx-3" active-class="secondary white--text" show-arrows>
            <v-chip v-for="(item, idx) in catalogues.categorias" :key="idx">{{ item }}</v-chip>
          </v-chip-group>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="2">
        <v-select v-if="catalogues.regiones.length" v-model="filter2" elevation="5" :items="catalogues.regiones" label="Región"
        class="mt-2" solo hide-details prepend-inner-icon="mdi-map-marker" clearable ></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="filter3" elevation="5" class="mt-2" label="Buscar..." @keydown.enter="getFilter()" clearable solo hide-details>
          <template v-slot:append>
            <v-btn color="primary" elevation="0" @click="getFilter()">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="items.length" style="height: 850px; overflow-y: auto;">
      <v-col cols="12" md="6" lg="4" v-for="(item, idx) in items" :key="idx">
        <v-lazy :min-height="200" :options="{ 'threshold': 0.5 }" transition="fade-transition">
          <v-hover v-slot="{ hover }" open-delay="120" close-delay="120">
            <v-card height="410" :elevation="hover ? 8 : 2" :class="{ 'on-hover': hover, 'overflow-hidden': true }" style="position: relative;">
              <v-img :src="null" class="white--text align-end" gradient="to bottom, rgba(82,113,255,.1), rgba(82, 113, 255,.5)" height="250px">
                <v-card-title>
                  <v-img :src="item.logo" height="50" contain></v-img>
                </v-card-title>
                <v-card-title>
                  <v-chip small color="#7000e0" dark>{{ item.categoria }}</v-chip>
                </v-card-title>
                <v-card-title>
                  <v-chip v-for="(item2, idx2) in item.etiquetas" color="blue-grey" class="mx-1" dark small :key="idx2">{{ item2 }}</v-chip>
                  <v-chip v-if="item.region" color="blue-grey" class="mx-1" dark small>
                    {{ item.region }}
                    <v-icon right small>mdi-map-marker</v-icon>
                  </v-chip>
                </v-card-title>
              </v-img>
              <v-btn v-if="item.contactado" absolute small color="secondary" class="white--text mt-10 mx-2" elevation="1" fab right top>
                <v-icon>mdi-email-check</v-icon>
              </v-btn>
              <v-card-actions class="mx-2">
                <span class="title">{{ item.titulo }}</span>
                <v-spacer></v-spacer>
                <v-btn text rounded class="text-normal" color="blue-grey" elevation="0" :href="item.pagina_web" target="_blank">
                  Sitio web
                  <v-icon right>mdi-web</v-icon>
                </v-btn>
                <v-btn text rounded class="text-normal" color="success" elevation="0" :disabled="item.contactado" @click="contact(item.id)">
                  Contactar
                  <v-icon right>mdi-email</v-icon>
                </v-btn>
              </v-card-actions>
              <v-card-text class="text-justify" v-html="item.subtitulo" style="cursor: pointer;" @click="showDetail(item)">
              </v-card-text>
            </v-card>
          </v-hover>
        </v-lazy>
      </v-col>
    </v-row>
    <v-row v-else-if="!firstTime && !loading" class="text-center mt-10">
      <v-col cols="12">
        <div style="font-size: 30px; color: #bdbdbd;">No se obtuvieron resultados</div>
        <v-icon :size="80" color="grey lighten-1">mdi-magnify-remove-outline</v-icon>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogs.detail" scrollable max-width="800px">
      <v-card>
        <v-toolbar dense color="primary" dark elevation="0">
          <v-toolbar-title>Detalle del proveedor - {{ form.title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" class="text-normal" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text style="max-height: 800px;">
          <v-container class="mt-5 content" v-html="form.detail">
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.contact" persistent max-width="450">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text class="subtitle">¿Realmente desea contactar con el proveedor?</v-card-text>
        <v-card-text>Se le enviará un correo predeterminado al proveedor avisándole de que su empresa desea contactarlo </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" class="text-normal" color="blue-grey" @click="cancel()">
            Cancelar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" class="text-normal" color="success" @click="contact()">
            Aceptar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay absolute :value="loading" opacity="0.8">
      <div v-if="loading" class="text-center">
        <v-progress-circular indeterminate size="60" color="primary" class="mb-5"></v-progress-circular>
        <p>Cargando...</p>
      </div>
    </v-overlay>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import services from '@/utils/services'
import _ from 'lodash'

export default {
  name: 'Form',
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    catalogues: {
      categorias: [],
      regiones: []
    },
    filter: null,
    filter2: null,
    filter3: null,
    suppliers: [],
    items: [],
    firstTime: true,
    contacted: null,
    form: {
      title: '',
      detail: ''
    },
    dialogs: {
      detail: false,
      contact: false
    }
  }),
  mounted() {
    this.refresh()
  },
  computed: {
    company() {
      return this.$store.state.general.company
    },
    report() {
      return this.$store.state.general.report
    }
  },
  watch: {
    company: function (val) {
      this.clear()
      this.refresh()
    },
    report: function (val) {
      if (val) {
        this.setReport({ val: false })
      }
    },
    filter: function (val) {
      this.getFilter()
    },
    filter2: function (val) {
      this.getFilter()
    },
    filter3: function (val) {
      if (_.isNil(val)) {
        this.getFilter()
      }
    }
  },
  methods: {
    ...mapActions('general', [
      'setReport'
    ]),
    async refresh() {
      if (this.$store.state.general.company) {
        this.loading = true
        const category = await this.axios.get(services.routes.supplier + '/category')
        if (category.data.data) {
          this.catalogues.categorias = category.data.data
        }
        const region = await this.axios.get(services.routes.supplier + '/region')
        if (region.data.data) {
          this.catalogues.regiones = region.data.data
        }
        let params = {
          id: this.$store.state.general.company
        }
        this.axios.get(services.routes.supplier, { params })
          .then(response => {
            this.suppliers = response.data.data
            for (let i = 0; i < this.suppliers.length; i++) {
              if (this.suppliers[i].logo) {
                let data = this.suppliers[i].logo.data
                this.suppliers[i].logo = 'data:image/png;base64,' + btoa(new Uint8Array(data).reduce((data, byte) => data + String.fromCharCode(byte), ''))
              }
            }
            this.getFilter()
            this.loading = false
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    clear() {
      this.suppliers = [],
      this.items = [],
      this.firstTime = true,
      this.form = {
        title: '',
        detail: ''
      }
    },
    getFilter() {
      this.loading = true
      this.items = []
      setTimeout(() => {
        for (let i = 0; i < this.suppliers.length; i++) {
          if (_.isNil(this.filter) && _.isNil(this.filter2) && _.isNil(this.filter3)) {
            this.items.push(this.suppliers[i])
          }
          else {
            let textSearch = this.suppliers[i].titulo + ' ' + this.suppliers[i].categoria + ' ' + this.suppliers[i].subtitulo + ' ' +
            this.suppliers[i].descripcion.replace(/(<([^>]+)>)/ig, '') + ' ' + this.suppliers[i].etiquetas.join(' ') + ' ' +
            this.suppliers[i].pagina_web + ' ' + this.suppliers[i].email + ' ' + this.suppliers[i].region
            textSearch = textSearch.toLowerCase()
            
            let checkFilter = (!_.isNil(this.filter) && this.suppliers[i].categoria === this.catalogues.categorias[this.filter]) ? true : false
            let checkFilter2 = (!_.isNil(this.filter2) && this.suppliers[i].region.toString().toLowerCase() === this.filter2.toString().toLowerCase()) ? true : false
            let checkFilter3 = (!_.isNil(this.filter3) && textSearch.search(this.filter3.toString().toLowerCase()) >= 0) ? true : false
            let flag = false;

            if (!_.isNil(this.filter) && _.isNil(this.filter2) && _.isNil(this.filter3) && checkFilter) {
              flag = true
            }
            else if (_.isNil(this.filter) && !_.isNil(this.filter2) && _.isNil(this.filter3) && checkFilter2) {
              flag = true
            }
            else if (_.isNil(this.filter) && _.isNil(this.filter2) && !_.isNil(this.filter3) && checkFilter3) {
              flag = true
            }
            else if (!_.isNil(this.filter) && !_.isNil(this.filter2) && _.isNil(this.filter3) && checkFilter && checkFilter2) {
              flag = true
            }
            else if (_.isNil(this.filter) && !_.isNil(this.filter2) && !_.isNil(this.filter3) && checkFilter2 && checkFilter3) {
              flag = true
            }
            else if (!_.isNil(this.filter) && _.isNil(this.filter2) && !_.isNil(this.filter3) && checkFilter && checkFilter3) {
              flag = true
            }
            else if (!_.isNil(this.filter) && !_.isNil(this.filter2) && !_.isNil(this.filter3) && checkFilter && checkFilter2 && checkFilter3) {
              flag = true
            }
            
            if (flag) {
              this.items.push(this.suppliers[i])
            }
          }
        }
        this.firstTime = false
        this.loading = false
      }, 300);
    },
    showDetail(data) {
      if (data.descripcion) {
        this.form.title = data.titulo
        this.form.detail = data.descripcion
        this.dialogs.detail = true
      }
    },
    cancel() {
      this.form = {
        title: '',
        detail: ''
      }
      this.contacted = null
      this.dialogs.detail = false
      this.dialogs.contact = false
    },
    contact(id) {
      if (!id) {
        this.loading = true
        let data = {
          id: this.contacted,
          empresa: this.$store.state.general.company
        }
        this.axios.post(services.routes.supplier + '/contact', { data })
          .then(response => {
            this.firstTime = true
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Proveedor contactado exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.contacted = id
        this.dialogs.contact = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .text-normal {
    text-transform: none !important;
  }
  .title {
    font-size: 20px !important;
    font-weight: bold;
    font-family: 'Quicksand', sans-serif !important;
    color: #5271ff;
  }
  .subtitle {
    font-size: 16px !important;
    font-weight: bold;
    font-family: 'Quicksand', sans-serif !important;
  }
  .no-records {
    font-size: 30px !important;
    font-weight: bold;
    font-family: 'Quicksand', sans-serif !important;
    color: grey;
  }
  .content ::v-deep {
    .ql-align-right {
      text-align: right;
    }
    .ql-align-center {
      text-align: center;
    }
    .ql-align-left {
      text-align: left;
    }
    .ql-align-justify {
      text-align: justify;
    }
  }
</style>
